import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { PublicClientApplication, EventType } from "@azure/msal-browser";

export class Config {
    static __config: any; 
    static getConfig = async () => {    
        if(Config.__config)
            return Config.__config; 
        let config = await axios.get("/api/portal/uiconfig");
        if(config.status === 200) {            
            Config.__config = config.data;
            console.log("Initial config:", {...Config.__config});
            // allow overriding config in UI project, fallback is from APIs
            Object.keys(Config.__config).forEach((prop) => {
                //console.log(`Mapping prop:${prop} (env[prop]:${process.env[prop]} ?? config[prop]:${Config.__config[prop]})`);
                Config.__config[prop] = process.env[prop] ?? Config.__config[prop];
            });
            console.log("Final config:", Config.__config);
            return Config.__config;
        }
        else
            throw new Error("Failed to get config!");
    };
}

const portalClientRaw = axios.create({
    validateStatus: function (status) {
        if (status === 403)
            alert("Sorry your account is not Authorised to do that.");
        return status >= 200 && status < 300;
    }
});
const portalClient = (portalClientRaw as any);
portalClient.CancelToken = axios.CancelToken;
const windowWrapper = (window as any);

// Auth0 ========================================================================================
portalClient.initAuth0 = async () => {
    // docs for auth0 indicate that createAuth0Client does everything needed to get token, etc
    // unlesss login required. 
    try {
        let config = await Config.getConfig();
        if (document.location.href.endsWith("logout")) {
            return null;
        }
        else if(windowWrapper.auth0) {
            windowWrapper.accessToken = await windowWrapper.auth0.getTokenSilently();  
            if(windowWrapper.accessToken)
                return windowWrapper.accessToken;
        }
        console.log('await createAuth0Client()...');
        windowWrapper.auth0 = await createAuth0Client({
            // auspost dev - m2m
            //domain: 'playpen.auspost-npe.auth0.com',
            //client_id: 'hrfLoWdyjNZd4J4eQmVJ68Zoxs072CmB',
            // auspost dev - spa
            domain: `${config.REACT_APP_AUTH0_DOMAIN}`, //'playpen.auspost-npe.auth0.com',
            clientId: `${config.REACT_APP_AUTH0_CLIENT_ID}`, //'3JXL1yij1sKxTwjJgI53ZR5nExoGx6Nt'
            authorizationParams: {
                audience: `${config.REACT_APP_AUTH0_AUDIENCE}`, //'partner.voucherproducts.auspost.com.au'
                redirect_uri: `${config.REACT_APP_UI_BASE_URL}/idpresponse`
            }            
            // simon playpen
            //,domain: 'voucherplusdevtest.au.auth0.com',
            //clientId: 'b2mSwpL6dtgihizbNXYtFO16qVYEVxy8'
            // simon playpen - m2m
            //domain: 'voucherplusdevtest.au.auth0.com',
            //client_id: 'au7qyzNLcrWBpB8xxXnbmmwffCt5rrRb',
            //domain: process.env.AUTH0_DOMAIN,
            //client_id: process.env.AUTH0_DOMAIN,            
        });
        //https://voucherplusdevtest.au.auth0.com/api/v2/
        console.log('got client!', windowWrapper.auth0);
        console.log('auth0.getTokenSilently()...');
        windowWrapper.accessToken = await windowWrapper.auth0.getTokenSilently();        
        console.log('got token!', windowWrapper.accessToken);
        let claims = await windowWrapper.auth0.getIdTokenClaims();
        console.log('got claims!', claims);
        portalClient.defaults.headers.common = { 'Authorization': `Bearer ${windowWrapper.accessToken}` };

        portalClient.refreshToken = async (failedRequest:any) => {
            windowWrapper.accessToken = await windowWrapper.auth0.getTokenWithPopup({
                authorizationParams: {
                  audience: 'partner.voucherproducts.auspost.com.au',
                  //scope: 'read:rules'
                }
              });
            console.log("refreshed token: ", windowWrapper.accessToken);
            portalClient.defaults.headers.common = { 'Authorization': `Bearer ${windowWrapper.accessToken}` };
            failedRequest.response.config.headers['Authorization'] = `Bearer ${windowWrapper.accessToken}`;
            return Promise.resolve(windowWrapper.accessToken);
        };

        portalClient.getUser = () => {
            return windowWrapper.auth0.getUser();
        }
        
        portalClient.handleRedirectCallback = () => {
            if(!document.location.href.endsWith("logout"))
                return windowWrapper.auth0.handleRedirectCallback();
        }

        portalClient.logout = () => {
            let logoutParams = {logoutParams: {returnTo: `${config.REACT_APP_UI_BASE_URL}/logout`}};
            console.log("logout->params", logoutParams)
            return windowWrapper.auth0.logout(logoutParams);
        }

        return windowWrapper.accessToken;
    }
    catch(err) {
        console.log('await loginWithRedirect()...', err);
        await windowWrapper.auth0.loginWithRedirect();
        return false;
    }      
}
// Auth0 ========================================================================================

// Azure AD ========================================================================================
portalClient.initAzureAd = async (setAccount:any | null = null) => {
    if (windowWrapper.msalInstance || document.location.href.endsWith("logout")) return windowWrapper.msalInstance;
    console.log("Building MSAL instance...");
    let config = await Config.getConfig();
    //Logger.Debug("Got config for msal", config);
    const auth = {
        // auspost
        "clientId": `${config.REACT_APP_AZAD_CLIENT_ID}`, //"3b5a850a-bb3b-44bc-b98e-353dc9afe534"
        "authority": `${config.REACT_APP_AZAD_AUTHORITY}`, // "https://login.microsoftonline.com/fced7ed8-363a-4005-8037-adc1290883f6"
        "redirectUri": `${config.REACT_APP_UI_BASE_URL}/idpresponse`, //"https://localhost:3000/idpresponse"
        "postLogoutRedirectUri": `${config.REACT_APP_UI_BASE_URL}/logout`,
        //"scopes": ["3b5a850a-bb3b-44bc-b98e-353dc9afe534/.default"]
        // simon test
        /*"clientId": "0d0fb067-e62e-437d-a5bd-ec49608408bd",
        "authority": "https://login.microsoftonline.com/93cbb491-badb-4e7d-9dc9-79d8ce500182",
        "redirectUri": "https://localhost:3000/idpresponse",
        "postLogoutRedirectUri": "https://localhost:3000/logout"*/
      };
    windowWrapper.msalInstance = new PublicClientApplication({ auth: auth });

    // Set active acccount on page load
    console.log("Getting acounts...", windowWrapper.msalInstance);
    let accounts = windowWrapper.msalInstance.getAllAccounts();
    console.log("accounts: ", accounts);
    if (accounts.length > 0) {
        windowWrapper.msalInstance.setActiveAccount(accounts[0]);
    }

    windowWrapper.msalInstance.addEventCallback((event:any) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            windowWrapper.msalInstance.setActiveAccount(account);
        }
    });
    // handle auth redired/do all initial setup for msal
    windowWrapper.msalInstance.handleRedirectPromise().then(async (authResult:any)=>{
        // Check if user signed in 
        const account = windowWrapper.msalInstance.getActiveAccount();
        console.log("Got account:", account);
        if(!account){
            // redirect anonymous user to login page 
            windowWrapper.msalInstance.loginRedirect();
        }
        else 
        {
            windowWrapper.accessToken = (await windowWrapper.msalInstance.acquireTokenSilent({
                account: account, 
                scopes: [config.REACT_APP_AZAD_SCOPE]
            })).accessToken;
                
            console.log("Got access token!", windowWrapper.accessToken);
            portalClient.defaults.headers.common = { 'Authorization': `Bearer ${windowWrapper.accessToken}` };
            if(setAccount)
                setAccount(account);
        }
    }).catch((err:any)=>{
        console.log(err);
        alert("Login error!");
        windowWrapper.msalInstance.loginRedirect();
    });

    portalClient.refreshToken = async (failedRequest:any) => {
        windowWrapper.accessToken = (await windowWrapper.msalInstance.acquireTokenPopup()).accessToken;
        console.log("refreshed token: ", windowWrapper.accessToken);
        portalClient.defaults.headers.common = { 'Authorization': `Bearer ${windowWrapper.accessToken}` };
        failedRequest.response.config.headers['Authorization'] = `Bearer ${windowWrapper.accessToken}`;
        return Promise.resolve(windowWrapper.accessToken);
    };

    portalClient.logout = () => {
        const logoutRequest = {
            ...auth,
            account: windowWrapper.msalInstance.getActiveAccount()            
          };
          windowWrapper.msalInstance.logoutRedirect(logoutRequest);
        return windowWrapper.msalInstance.logout();
    }
    return windowWrapper.msalInstance;
  };
// Azure AD ========================================================================================

// Test Client ========================================================================================
portalClient.initTestClient = async () => {
    try {
        let config = await Config.getConfig();
        console.log("Got config for test client:", config);
        return "dummy token";
    }
    catch(err) {
        console.error("Error getting config for test", err);
    }
}
// Test Client ========================================================================================

// This should be called when we know we need oauth 
portalClient.createRefreshInterceptor = () => {
    // set flag so that we use refresh interceptor for all instances
    windowWrapper.useAuthRefresh = true;
    createAuthRefreshInterceptor(portalClient, windowWrapper.refreshToken);
}
if (windowWrapper.useAuthRefresh)
    createAuthRefreshInterceptor(portalClient, windowWrapper.refreshToken);

export default portalClient;