import { useCallback, useState } from 'react';
import { Heading, Button, Label, Input, Notification } from '@auspost/postmaster-react';
import styles from './Denominations.module.scss';
import ProductSelect from '../components/molecules/ProductSelect';
import { emptyProduct } from '../components/organisms/ProductDetail';
import ChangeTrackInput from '../components/molecules/ChangeTrackInput';
import DenominationSelect from '../components/molecules/DenominationSelect';
import portalClient from '../utils/PortalClient';
import ChangeTrackCheckbox from '../components/molecules/ChangeTrackCheckbox';

const DenominationAdd = ({ partner }: { partner: any }) => { //test
    const [product, setProduct] = useState(emptyProduct);
    const [version, setVersion] = useState(1);
    const [denomination, setDenomination] = useState<any | null>(null);
    const [editingDenomination, setEditingDenomination] = useState<any | null>(emptyDenomination);

    const isEditing = () => editingDenomination?.DenominationID && editingDenomination?.DenominationID > 0;

    const doSave = async () => {
        var body = {
            DenominationID: editingDenomination.DenominationID, intProductID: editingDenomination.intProductID, monValue: editingDenomination.monValue,
            strDenomination: editingDenomination.strDenomination, intStockCount: editingDenomination.intStockCount, intDaysStock: editingDenomination.intDaysStock,
            blnOnSale: editingDenomination.blnOnSale, blnStockLevelMonitoring: editingDenomination.blnStockLevelMonitoring,
            strStockLevelMonitoringEmail: editingDenomination.strStockLevelMonitoringEmail, strUpdatingUsername: "USER"
        };
        let response: Response;
        if(isEditing()) {
            response = await portalClient.put(`/api/portal/denominations/${body.DenominationID}`, body);
        }
        else {
            response = await portalClient.post(`/api/portal/denominations`, body);
        }
        if(response.status === 200) {
            alert('Denomination Saved');
            setEditingDenomination(emptyDenomination);
            setVersion(version + 1);
        }
        else {
            alert('Denomination failed to Save!');
            console.error("Unexpected response status", response);
        }        
    }

    const doToggleStatus = async () => {
        // Looks like usp_DenominationUpdate can be used to set blnOnSale which actually indicates the status but then audit trail is lost
        // so we'll chain them together....
        let newStatus = denomination?.strStatus === "Available" ? 2 : 1;
        let setResp = await portalClient.post(`/api/portal/dbproxy`,
            {
                spName: "usp_DenominationSetStatus",
                spParams: { intDenominationID: denomination.DenominationID, intStatusID: newStatus, strPartner: partner.strPartnerName, strUsername: "TODO" }
            });
        console.log("usp_DenominationSetStatus resp:", setResp);
        let updateResp = await portalClient.post(`/api/portal/dbproxy`,
                {
                    spName: "usp_DenominationUpdate",
                    spParams: {
                        intDenominationID: denomination.DenominationID, intProductID: denomination.intProductID, monValue: denomination.monValue,
                        strDenomination: denomination.strDenomination, intStockCount: denomination.intStockCount, intDaysStock: denomination.intDaysStock,
                        blnOnSale: !denomination.blnOnSale
                    }
                });
        console.log("usp_DenominationUpdate resp:", updateResp);
        let newEntity = { ...denomination, strStatus: "Loading..." };
        console.log("denomination after update:", newEntity);
        //reset will cause details to refresh
        setDenomination(newEntity);
    }

    const setSelectedProduct = useCallback((prod: any) => {        
        setProduct(prod);
        setDenomination(null);
        setEditingDenomination(emptyDenomination);
    }, []);

    const doAdd = () => {
        console.log("doAdd()");
        setEditingDenomination({ ...emptyDenomination, strProductName: product?.ProductName, intProductID: product?.ProductID, status: "Available" });
    }

    const doEdit = () => {
        console.log("doEdit()");
        setEditingDenomination(emptyDenomination);
        let denom = { ...denomination, strStatus: denomination.strStatus ?? "Available" };
        //console.log("doEdit()",)
        setEditingDenomination(denom);
    }

    const doCancel = () => {
        console.log('doCancel');
        setEditingDenomination(emptyDenomination);
    }

    return (
        <>
            <div className={`main container ${styles.denominationAdd}`}>
                {/*<Heading level="h1">New Denomination</Heading>*/}
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                Select Product &amp; Denomination
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doAdd}>Add Denomination</Button>
                            </div>
                        </div>
                    </div>
                    <div className="table-content">
                        <div className='flex-container fields row'>
                            <ProductSelect className='flex-cell-fill' product={product} setProduct={setSelectedProduct} partner={partner} />
                            <Notification isDismissible={false} className='flex-cell-6' message="NOTE: Any changes or additions made here will now take immediate effect. Please ensure they are correct." status="info" type="alert" />
                        </div>
                        <div className='flex-container fields row'>
                            {product && <>
                                <DenominationSelect version={version} className='flex-cell-fill' denomination={denomination} product={product}
                                    setDenomination={(newDen: any) => {
                                        setEditingDenomination(null);
                                        setDenomination(newDen);
                                    }} />
                                {denomination?.DenominationID && <div className='flex-cell-2'>
                                    <Label>Denomination Status</Label>
                                    <Input value={denomination?.strStatus ?? ""} type="text" className='small' disabled />
                                </div>}
                                <div className='actions flex-cell-6'>
                                    {denomination?.strStatus && denomination?.strStatus !== "Unknown" && <>
                                        <Button size="small" variant="secondary" onClick={doToggleStatus}>Toggle Denomination Voucher Status</Button>
                                        <Button size="small" variant="secondary" onClick={doEdit}>Modify Definition</Button>
                                    </>}
                                </div>
                            </>}
                        </div>
                    </div>
                </div><br />
                {editingDenomination?.intProductID > 0 && <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                {isEditing() ? 'Edit' : 'New'} Denomination
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doSave}>Save</Button>
                                <Button size="small" variant="secondary" onClick={doCancel}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                    <div className="table-content">
                        <div className="detail flex-container row fields">
                            <div className="flex-container">
                                <div className="flex-cell-2"><Label>Product Name</Label></div>
                                <div className="flex-cell-fill">
                                    <ChangeTrackInput disabled trackChanges={isEditing()} entity={editingDenomination} setEntity={setEditingDenomination} field="strProductName" />
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="flex-cell-2"><Label>Denomination Value</Label></div>
                                <div className="flex-cell-fill">
                                    <ChangeTrackInput trackChanges={isEditing()} entity={editingDenomination} setEntity={setEditingDenomination} field="monValue" />
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="flex-cell-2"><Label id="desc-label">Description</Label></div>
                                <div className="flex-cell-fill">
                                    <ChangeTrackInput ariaLabelledBy='desc-label' trackChanges={isEditing()} entity={editingDenomination} setEntity={setEditingDenomination} field="strDenomination" />
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="flex-cell-2"><Label>Stock Level Monitor</Label></div>
                                <div className="flex-cell-fill">
                                    <ChangeTrackCheckbox trackChanges={isEditing()} entity={editingDenomination}
                                        field="blnStockLevelMonitoring" setEntity={setEditingDenomination} />
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="flex-cell-2"><Label>Stock Level Email</Label></div>
                                <div className="flex-cell-fill">
                                    <ChangeTrackInput required={editingDenomination.blnStockLevelMonitoring} disabled={!editingDenomination.blnStockLevelMonitoring} 
                                        trackChanges={isEditing()} entity={editingDenomination} field="strStockLevelMonitoringEmail" setEntity={setEditingDenomination} />
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="flex-cell-2"><Label>Days of Stock Warning</Label></div>
                                <div className="flex-cell-fill">
                                    <ChangeTrackInput trackChanges={isEditing()} entity={editingDenomination} setEntity={setEditingDenomination} field="intDaysStock" />
                                </div>
                            </div>
                        </div>
                        <div className="flex-container row fields actions">
                            <Button size="small" variant="primary" onClick={doSave}>Save</Button>
                            <Button size="small" variant="secondary" onClick={doCancel}>Cancel</Button>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    );
}

export interface IDenomination {
    DenominationID: number, intProductID: number, monValue: number,
    strDenomination: string, intStockCount: number, intDaysStock: number,
    blnOnSale: boolean, blnStockLevelMonitoring: boolean,
    strStockLevelMonitoringEmail: string, strUpdatingUsername: string, strStatus: string
};

export const emptyDenomination = {
    DenominationID: -1, intProductID: -1, monValue: 0,
    strDenomination: "", intStockCount: 0, intDaysStock: 30,
    blnOnSale: true, blnStockLevelMonitoring: false,
    strStockLevelMonitoringEmail: "", strUpdatingUsername: "USER", 
    strStatus: "Unknown"
};

export const isValidDenomination = (denomination: IDenomination, setInvalidFields: React.Dispatch<React.SetStateAction<string[]>>) => {
    let invalidFields: string[] = [];
    if (!denomination.monValue)
        invalidFields.push("Denomination Value");
    if (!denomination.strDenomination)
        invalidFields.push("Description");
    if (denomination.blnStockLevelMonitoring && (!denomination.strStockLevelMonitoringEmail || denomination.strStockLevelMonitoringEmail.length <= 0))
        invalidFields.push("Stock Level Email");
    setInvalidFields(invalidFields);
    return invalidFields.length <= 0;
}

export default DenominationAdd;
