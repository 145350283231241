import { useEffect, useState } from 'react';
import { Select, Input } from '@auspost/postmaster-react';
import { Icon } from 'semantic-ui-react'
import styles from './ChangeTrackInput.module.scss';

const ChangeTrackSelect = (
        { trackChanges, entity, setEntity, field, options, disabled, ...props }:
        { trackChanges: any, entity: any, setEntity: any, field: any, options: any, disabled?: any }) => {
    const [originalField, setOriginalField] = useState<string>();
    const [originalFieldValue, setOriginalFieldValue] = useState<string>();

    const handleChange = (newVal: any) => {
        console.log('handleChange->newVal', newVal);
        let newObj = [];
        newObj[field] = newVal;
        setEntity({ ...entity, ...newObj });
    }

    useEffect(() => {
        if(entity && originalField === undefined) {
            //console.log("setOriginalField", entity, entity[field]);
            setOriginalField(entity[field]);
        }
    }, [entity, field, setOriginalField, originalField]);

    useEffect(() => {
        if(entity && originalFieldValue === undefined) {
            let oVal = options.find((o:any) => o.value === entity[field])?.label;
            //console.log("setOriginalFieldValue", options, oVal);
            setOriginalFieldValue(oVal);
        }
    }, [entity, field, options, setOriginalFieldValue, originalFieldValue]);


    return (
        <>{entity && <div className={`flex-container ${styles.cti}`} {...props}>
            {trackChanges &&  <>
                <Input type="text" disabled={true} value={originalFieldValue} className="flex-cell-fill small"></Input>&nbsp;&nbsp;
                <div className='flex-cell-auto change-icon'>{entity[field].toString() !== originalField?.toString() ? <Icon size='large' name="arrow right" /> : 
                <Icon size='large' />}</div>
            </>}
            <>
                <Select className={`flex-cell-fill small ${trackChanges && 'track'}`} options={options} disabled={disabled} value={`${entity[field]}`}
                    onChange={(e) => {handleChange(e.target.value);}}/>
            </>
        </div>}</>
        );
}

export default ChangeTrackSelect;