import { useState, useEffect, useCallback } from 'react';
import { Route, useNavigate, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import Home from './pages/Home';
import portalClient2 from './utils/PortalClient';
import './custom.scss'
import MaintainBatch from './pages/MaintainBatch';
import UploadBatch from './pages/UploadBatch';
import ReviewStock from './pages/ReviewStock';
import ProductAnalysisDetailed from './pages/ProductAnalysisDetailed';
import ProductAdd from './pages/ProductAdd';
import ProductUpdate from './pages/ProductUpdate';
import Denominations from './pages/Denominations';
import Settlements from './pages/Settlements';
import ProductAnalysisSummary from './pages/ProductAnalysisSummary';
import ProductStatus from './pages/ProductStatus';

export default function App({ bypassAuth = false }: { bypassAuth?: boolean }) {
    //static displayName = App.name;
    const portalClient = (portalClient2 as any);
    const [partner, setPartner] = useState<any | null>(null);

    const [user, setUser] = useState<any | null>(null);
    let navigate = useNavigate();

    const doLogout = () => {
        window.localStorage.removeItem("VoucherPlusCurrentPartner");
        portalClient?.logout();
    }

    const setAzureAdAccount = useCallback((account: any) => {
        //console.log("setting User from account: ", account)
        let user = { name: account.username, email: account.username, sub: account.idTokenClaims.sub };
        console.log("setUser()...", user);
        setUser(user);
    }, []);
    const initAzureAd = useCallback(async () => {
        console.log("portalClient.initAzureAd())...");
        await portalClient.initAzureAd(setAzureAdAccount);
        // now if we're on the /internal url navigate to route
        if (window.location.href.endsWith("/internal")) {
            navigate("/");
        }
    }, [portalClient, setAzureAdAccount, navigate]);
    const initAuth0 = useCallback(async () => {
        console.log("portalClient.initAuth0()...");
        let token = await portalClient.initAuth0();
        if (token) {
            let user = await portalClient.getUser();
            console.log("setUser()...", user);
            setUser(user);
            // now if we're on the /internal url navigate to route
            if (window.location.href.endsWith("/external")) {
                navigate("/");
            }
        }
    }, [portalClient, navigate]);
    const initTestClient = useCallback(async () => {
        console.log("portalClient.initTestClient()...");
        let token = await portalClient.initTestClient();
        if (token) {
            console.log("setUser() test user...");
            setUser({
                email: "testuser@example.com",
                name: "A Test User",
                sub: "A test sub"
            });
        }
    }, [portalClient]);
    useEffect(() =>{
        if (!user?.sub)
            return;
        let partnerStr = window.localStorage.getItem("VoucherPlusCurrentPartner");
        try {
            if (partnerStr) {
                console.log("Get partnerStr:", partnerStr);
                let partner = JSON.parse(partnerStr);
                setPartner(partner);
            }
        }
        catch (err) {
            console.warn("Tried but failed to set partner from localstorage", partnerStr);
        }
    }, [setPartner, user?.sub]);
    useEffect(() => {
        if (user?.sub)
            return;
        console.log("useEffect->init auth info");
        // set the types if specific url
        if (window.location.href.endsWith("/internal")) {
            window.localStorage.setItem("portalAuthType", "internal");
        }
        else if (window.location.href.endsWith("/external")) {
            window.localStorage.setItem("portalAuthType", "external");
        }

        // fallback - default is external
        if (!window.localStorage.getItem("portalAuthType")) {
            window.localStorage.getItem("portalAuthType");
        }

        //init the appropriate provider
        if (window.localStorage.getItem("portalAuthType") === "internal")
            initAzureAd();
        else if (bypassAuth)
            initTestClient();
        else
            initAuth0();
    }, [initAzureAd, initAuth0, initTestClient, user?.sub, bypassAuth]);

    const SetInternal = () => {
        return (<div className={`main container`}>Checking Internal login...</div>)
    };

    const SetExternal = () => {
        return (<div className={`main container`}>Checking External login...</div>)
    };

    const LoggedOut = () => {
        setPartner(null);
        return (<div className={`main container`}>Thanks, you have been logged out and can now close this window</div>)
    };

    useEffect(() => {
        const checkUser = async () => {
            if (!partner?.PartnerID)
                return;
            console.log("Checking useremail", partner?.PartnerID);
            let checkUserResp = await portalClient.post(`/api/portal/useremail`,
                {
                    intPartnerID: partner?.PartnerID
                });
            console.log("api/portal/useremail response:", checkUserResp);            
        }
        checkUser();
    }, [partner?.PartnerID, portalClient])

    const ProcessIdpResponse = () => {
        const processResponse = async () => {
            console.log("Process idp response...", portalClient);
            if (window.location.href.includes("error=")) {
                alert("Unknown error logging in!");
                return;
            }
            try {
                console.log("await client.handleRedirectCallback()...");
                let result = await portalClient.handleRedirectCallback();
                console.log("redirectResult: ", result);
                console.log("await client.getUser()...");
                let user = await portalClient.getUser();
                console.log("user: ", user);
                setUser(user);
                navigate("/");
            }
            catch (error) {
                //alert("Unknown error logging in");
                console.error("Unknown error logging in", error);
            }
        }

        useEffect(() => { processResponse() }, [])

        return (<div className={`main container`}>Please wait, logging you in...</div>)
    };

    return (
        <Layout partner={partner} logout={doLogout} user={user} selectPartner={setPartner}>
            <Routes>
                <Route path='/' element={<Home user={user} selectedPartner={partner} setSelectedPartner={setPartner} portalClient={portalClient} />} />
                <Route path='/idpresponse' element={<ProcessIdpResponse />} />
                <Route path='/stock-management/maintain-batch' element={<MaintainBatch key='maintain-batch' partner={partner} />} />
                <Route path='/stock-management/activate-batch' element={<MaintainBatch key='activate-batch' isActivateView partner={partner} />} />
                <Route path='/stock-management/review-stock-levels' element={<ReviewStock partner={partner} />} />
                <Route path='/logout' Component={LoggedOut} />
                <Route path='/stock-management/load-stock' element={<UploadBatch partner={partner} />} />
                <Route path='/reports/product-analysis-detail' element={<ProductAnalysisDetailed partner={partner} />} />
                <Route path='/settlements' element={<Settlements partner={partner} />} />
                <Route path='/reports/product-analysis-summary' element={<ProductAnalysisSummary partner={partner} />} />
                <Route path='/reports/product-status' element={<ProductStatus partner={partner} />} />
                <Route path='/internal' Component={SetInternal} />
                <Route path='/external' Component={SetExternal} />
                {/*<Route path='/wsdemo' Component={WebSocketDemo} />*/}
                <Route path='/product-definition/add-product' element={<ProductAdd partner={partner} />} />
                <Route path='/product-definition/update-product' element={<ProductUpdate partner={partner} />} />
                <Route path='/product-definition/denominations/:pid?' element={<Denominations partner={partner} />} />
            </Routes>
        </Layout>
    );
}
