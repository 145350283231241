import 'semantic-ui-css/semantic.min.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  let elements = document?.getElementsByTagName('base');
  const baseUrl = elements?.length > 0 ?  elements[0]?.getAttribute('href') ?? undefined : undefined;
  root.render(
    <BrowserRouter basename={baseUrl}>              
      <App />        
    </BrowserRouter>
  );
  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
