import { useState, useEffect } from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styles from './NavMenu.module.scss';
//import { Divider, IconHelpFilled } from 'otc-ui-component-library';
import { Link as PmLink } from '@auspost/postmaster-react'
import { Breadcrumb } from 'semantic-ui-react';

const toTitleCase = (phrase: any) => {
    return phrase
      .toLowerCase()
      .split('-')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

function NavMenu({selectPartner, partner, logout, user}: {selectPartner: any, partner: any, logout: any, user: any}) {
    const [area, setArea] = useState("");
    const [subarea, setSubArea] = useState("");
    let location = useLocation();

    useEffect(() => {
        let areas = location?.pathname?.split('/');
        if(areas?.length > 0) {
            console.log("set area\\subarea:", location?.pathname?.split('/'));
            setArea(areas[1]);
            setSubArea(areas[2]);
        }
        else
        {
            setArea("");
            setSubArea("");
        }
    }, [location]);
    
    let navigate = useNavigate();
    return (
        <>
            <header className={styles.navMenu} style={{ backgroundColor: "white" }}>
                <div className="container">
                    <div className="header">
                        <div className="brand">
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.9985C0 25.706 4.82115 32.2717 11.6247 34.8408V1.15625C4.82115 3.72541 0 10.2911 0 17.9985Z" fill="#DC1928" />
                                <path d="M17.9983 0C17.5067 0 16.9992 0.015859 16.5234 0.0475771V1.3163H16.6186C23.4697 1.36388 28.9886 6.59736 28.9411 13.0044C28.8935 19.3797 23.3428 24.5181 16.5234 24.5022V35.9366C17.0151 35.9841 17.5067 36 17.9983 36C27.9419 36 35.9983 27.9436 35.9983 18C35.9983 8.05639 27.9419 0 17.9983 0Z" fill="#DC1928" />
                            </svg>
                            <span className="name">
                                Partner Portal
                            </span>
                        </div>
                        <div className="links">
                            <PmLink>
                                <svg width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM6.66667 6.66667H5.33333C5.33333 5.1767 6.58039 4 8 4C9.47684 4 10.6667 5.05762 10.6667 6.66667C10.6667 7.76564 9.95192 8.59666 8.66664 9.12963L8.66667 10H7.33333V9.12961C7.33333 8.5905 7.65799 8.10443 8.15596 7.89797C8.98896 7.55255 9.33333 7.15215 9.33333 6.66667C9.33333 5.83127 8.77316 5.33333 8 5.33333C7.30001 5.33333 6.66667 5.93095 6.66667 6.66667ZM8 12.6667C8.55229 12.6667 9 12.219 9 11.6667C9 11.1144 8.55229 10.6667 8 10.6667C7.44772 10.6667 7 11.1144 7 11.6667C7 12.219 7.44772 12.6667 8 12.6667Z" fill="#919194"/>
                                </svg> Help &amp; Support</PmLink>
                            {(partner /*TODO: remove cash?*/ || partner?.type === 'pre' || partner?.type === 'both') && 
                                <>Partner: <span className='partner' onClick={(e) => { selectPartner(null); navigate("/"); e.stopPropagation(); }}>{partner?.PartnerName}</span></>
                            }
                            {user?.email && <><span>Welcome <b>{user?.email}</b></span>
                                <PmLink onClick={(e) => { 
                                        logout(); e.stopPropagation(); e.nativeEvent.stopImmediatePropagation();
                                    }} href="#">
                                    Logout
                                </PmLink></>}
                        </div>
                    </div>
                </div>
                <div className="header-divider"></div>
                <div className="container">
                    <div>
                        {(partner /*TODO: remove cash?*/ || partner?.type) &&
                            <Menu pointing stackable>
                                <Menu.Item className={!area ? "active-menu" : ""} as={Link} to="/" name='home' />
                                {(partner /*TODO: remove cash?*/ || partner?.type === 'pre' || partner?.type === 'both') &&
                                    <>
                                        <Dropdown name="stock-management" className={area?.startsWith("stock") ? "active-menu" : ""} item text='Stock Management'>
                                            <Dropdown.Menu>
                                                <Dropdown.Item name="review-stock-levels" as={Link} to="/stock-management/review-stock-levels" text='Review Stock Levels' />
                                                <Dropdown.Item as={Link} to="/stock-management/maintain-batch" text='Maintain Batch' />
                                                <Dropdown.Item as={Link} to="/stock-management/load-stock" text='Load Stock' />
                                                <Dropdown.Item as={Link} to="/stock-management/activate-batch" text='Activate Batch' />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown className={area?.startsWith("product-definition") ? "active-menu" : ""} item text='Product Definition'>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={Link} to="/product-definition/update-product" text='Update Product' />
                                                <Dropdown.Item as={Link} to="/product-definition/add-product" text='Add Product' />
                                                <Dropdown.Item as={Link} to="/product-definition/denominations" text='Denominations' />
                                                {/*<Dropdown.Item as={Link} to="/product-definition/maintain-promotion" text='Maintain Promotion' />*/}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Menu.Item as={Link} to="/settlements" name='settlements' />
                                        <Dropdown item text='Reports'>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={Link} to="/reports/product-analysis-summary" text='Product Analysis Summary' />
                                                <Dropdown.Item as={Link} to="/reports/product-analysis-detail" text='Product Analysis Detail' />
                                                <Dropdown.Item as={Link} to="/reports/product-status" text='Product Status' />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/*<Menu.Item as={Link} to="/wsdemo" name='ws demo' />*/}
                                        {/*<Menu.Item as={Link} to="/admin" name='adminFunctions' />*/}
                                    </>}
                                {(partner?.type === 'cash' || partner?.type === 'both') &&
                                    <>
                                        <Dropdown item text='Reports'>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={Link} to="/claim-report" text='Claim Report' />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown item text='Inclusion List'>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={Link} to="/inclusion-list" text='Upload' />
                                                <Dropdown.Item as={Link} to="/inclusion-list-report" text='Upload Report' />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>}
                            </Menu>}
                    </div>
                </div>
            </header>
            {area && <div style={{ backgroundColor: "white" }}>
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Section><Link to="/">Home</Link></Breadcrumb.Section>
                        {area && <><Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section>{toTitleCase(area)}</Breadcrumb.Section></>}
                        {subarea && <><Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section>{toTitleCase(subarea)}</Breadcrumb.Section></>}
                    </Breadcrumb>
                </div>
            </div>}
        </>
    );    
}

export default NavMenu;
