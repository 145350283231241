import { useEffect, useState } from 'react';
import { Button, Label, Heading, Modal } from '@auspost/postmaster-react';
import styles from './Settlements.module.scss';
import DatePicker from '../components/DatePicker';
import portalClient from '../utils/PortalClient';
import { Spinner } from '../components/Shared';
import { downloadBlob, toCurr, validateDates } from '../utils/utils';
import moment from 'moment';

const Settlements = ({partner}:{partner: any}) => {
    const [fromDate, setFromDate] = useState<Date|null>(null);
    const [toDate, setToDate] = useState<Date|null>(null);
    const [settlements, setSettlements] = useState<any[]|null>(null);
    const [searching, setSearching] = useState<any|null>(false);
    const [searchMessage, setSearchMessage] = useState<string>("");
    const [searchedSettlement, setSearchedSettlement] = useState<any|null>(null);
    const [searching2, setSearching2] = useState(false);
    const [settlementDetails, setSettlementDetails] = useState<any|null>(null);
    const [searching3, setSearching3] = useState(false);
    const [productSales, setProductSales] = useState<any[]|null>(null);
    const [detail, setDetail] = useState<any|null>(null);

    useEffect(() => {
        let endDate = new Date();
        let startDate = new Date(endDate.setDate(endDate.getDate() - 7));
        endDate = new Date();
        console.log("Start/End date", startDate, endDate);
        setFromDate(startDate);
        setToDate(endDate);
    }, [])

    useEffect(() => {
        setSearchedSettlement(null);
        setSettlementDetails(null);
    }, [settlements]);

    useEffect(() => {
        setProductSales(null);
    }, [settlementDetails]);

    useEffect(() => {
        if(!searching)
            setSearchMessage("");
    }, [searching])

    const doExport = async () => {
        if(!fromDate || !toDate || !validateDates(fromDate, toDate))
            return;
        console.log('Exporting...', fromDate?.toJSON(), toDate?.toJSON());
        setSearching(true);
        setSearchMessage("Exporting...");
        try {            
            let response = await portalClient.get(`/api/portal/` + 
                `settlements?customerCode=${partner?.CustomerCode}&date_gte=${fromDate?.toJSON()}&date_lte=${toDate?.toJSON()}`);
            let settlements = response.data as any[];
            console.log('Got Settlements:', settlements);
            setSearchMessage(`Exporting... (0/${settlements.length} settlements)`);
            // Slow but at least it goes in order...
            /*for(let i=0; i<settlements.length;i++){
                let s = settlements[i];
                //console.log('Searching settlement detail:', s, i);
                setSearchMessage(`Exporting... (${i+1}/${settlements.length} settlements)`);
                let detailResp = await portalClient.get(`/api/portal/settlements/${s.SettlementId}`);                
                let details = detailResp.data as any[];
                console.log("Got details: ", details);
                for(let j=0; j<details.length;j++){
                    let d = details[j];
                    setSearchMessage(`Exporting... (${i+1}/${settlements.length} settlements -> ${j+1}/${details.length}) details`);                    
                    let salesResp = await portalClient.get(`/api/portal/settlements/${s.SettlementId}/${d.ProductID}/sales`);
                    let sales = salesResp.data.filter((d:any)=>d.StatusCode !== 1);
                    //console.log('Got sales:', sales);
                    for(let sale of sales) {                        
                        let row = {...s, ...d, ...sale};
                        delete row.StatusCode;
                        delete row.ErrorMessage;
                        if(exportData.length=== 0)
                            exportData += Object.keys(row).join(',') + "\n";
                        exportData += Object.values(row).join(',') + "\n";
                    }
                }
            }*/
            // Hypbrid - process settlement lines in order but details async
            var exportData:string = "";
            for(let i=0; i<settlements.length;i++){
                let s = settlements[i];
                //console.log('Searching settlement detail:', s, i);
                setSearchMessage(`Exporting... (${i+1}/${settlements.length} settlements)`);
                let detailResp = await portalClient.get(`/api/portal/settlements/${s.SettlementId}`);                
                let details = detailResp.data as any[];
                console.log("Got details: ", details);
                let getDetailJobs:Promise<string>[] = [];
                details.forEach((d, j) => {                    
                    let getDetail = async () => {                        
                        let exportDetail:string = "";
                        setSearchMessage(`Exporting... (${i+1}/${settlements.length} settlements -> ${j+1}/${details.length}) details`);
                        let salesResp = await portalClient.get(`/api/portal/settlements/${s.SettlementId}/${d.ProductID}/sales`);
                        let sales = salesResp.data.filter((d:any)=>d.StatusCode !== 1);
                        //console.log('Got sales:', sales);
                        let doneHeaders = false;
                        for(let sale of sales) {
                            let row = {...s, ...d, ...sale};
                            delete row.StatusCode;
                            delete row.ErrorMessage;
                            if(i === 0 && j === 0 && !doneHeaders)
                            {
                                console.log("Adding headers", row, i, j, );
                                exportDetail += Object.keys(row).join(',') + "\n";
                                doneHeaders = true;
                            }
                            exportDetail += Object.values(row).join(',') + "\n";
                        }
                        return exportDetail;
                    };
                    getDetailJobs.push(getDetail());
                });
                for(let detailJob of getDetailJobs)
                    exportData += await detailJob;
            }            
            // Fast but confusing for the user as counter may go backwards...
            /*let getSettlementJobs:Promise<void>[] = [];
            let getDetailJobs:Promise<void>[] = [];
            settlements.forEach((s, i) => {
                let getSettlement = async () => {
                    console.log('Searching settlement detail:', s, i);
                    setSearchMessage(`Exporting... (${i}/${settlements.length} settlements)`);
                    let detailResp = await portalClient.get(`/api/portal/settlements/${s.SettlementId}`);                
                    let details = detailResp.data as any[];
                    console.log("Get details: ", details);                
                    details.forEach((d, j) => {
                        let getDetail = async () => {                        
                            setSearchMessage(`Exporting... (${i+1}/${settlements.length} settlements -> ${j+1}/${details.length}) details`);
                            let salesResp = await portalClient.get(`/api/portal/settlements/${s.SettlementId}/${d.ProductID}/sales`);
                            let sales = salesResp.data.filter((d:any)=>d.StatusCode !== 1);
                            //console.log('Got sales:', sales);
                            for(let sale of sales) {                        
                                let row = {...s, ...d, ...sale};
                                delete row.StatusCode;
                                delete row.ErrorMessage;
                                if(exportData.length=== 0)
                                    exportData += Object.keys(row).join(',') + "\n";
                                exportData += Object.values(row).join(',') + "\n";
                            }
                        };                    
                        getDetailJobs.push(getDetail());
                    });                    
                }
                getSettlementJobs.push(getSettlement());
            });
            await Promise.allSettled(getSettlementJobs);
            await Promise.allSettled(getDetailJobs);*/
            console.log("All exportData:", exportData);
            const blob = new Blob([exportData],{ type: 'text/csv' });
            downloadBlob(blob, `settlements-${partner.PartnerID}-${moment(fromDate).format("YYYYMMDD")}-${moment(toDate).format("YYYYMMDD")}.csv`);
            setSearching(false);
        }
        catch(e) {
            alert("Error getting settlements");
            console.error("Error getting settlements", e);
            setSearching(false);
        };
        
    }

    const doSearch = async () => {
        if(!fromDate || !toDate || !validateDates(fromDate, toDate))
            return;
        console.log('Searching...', fromDate?.toJSON(), toDate?.toJSON());
        setSettlements(null);        
        setSearching(true);
        try {
            let response = await portalClient.get(`/api/portal/` + 
                `settlements?customerCode=${partner?.CustomerCode}&date_gte=${fromDate?.toJSON()}&date_lte=${toDate?.toJSON()}`);
            console.log('Got Settlements:', response.data);
            setSettlements(response.data);
            setSearching(false);
        }
        catch(e) {
            alert("Error getting settlements");
            console.error("Error getting settlements", e);
            setSearching(false);
        };
    }

    const doSearchDetail = async (settlement:any) => {
        console.log('Searching detail...', settlement);
        window.scrollTo(0, document.body.scrollHeight);
        setSearchedSettlement(settlement);
        setSettlementDetails(null);
        setSearching2(true);
        try {
            let response = await portalClient.get(`/api/portal/settlements/${settlement.SettlementId}`);
            console.log('Got details:', response.data);
            setSettlementDetails(response.data);
            setSearching2(false);
            window.scrollTo(0, document.body.scrollHeight);
        }
        catch(e) {
            alert("Error getting settlement details");
            console.error("Error getting settlement details", e);
            setSearching2(false);
        };
    }
    
    const doSearchSales = async (detail:any) => {
        console.log('Searching sales...', detail);
        window.scrollTo(0, document.body.scrollHeight);
        setProductSales(null);
        setSearching3(true);
        try {
            let response = await portalClient.get(`/api/portal/settlements/${searchedSettlement.SettlementId}/${detail.ProductID}/sales`);
            let sales = response.data.filter((d:any)=>d.StatusCode !== 1);
            console.log('Got sales:', sales);
            setProductSales(sales);
            setSearching3(false);
            window.scrollTo(0, document.body.scrollHeight);
        }
        catch(e) {
            alert("Error getting product sales");
            console.error("Error getting product sales", e);
            setSearching3(false);
        };
    }

    return (
        <>
            <div className={`main container ${styles.settlements}`}>
                {/*<Heading level="h1">Product Analysis Detailed Report</Heading>*/}
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                Settlement Report
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doExport}>Export</Button>
                                <Button size="small" variant="primary" onClick={doSearch}>Search</Button>                                
                            </div>
                        </div>
                    </div>
                    <div className="table-content">
                        <div className="flex-container row fields">
                            <div className="flex-row">
                                <div className="flex-cell-6">
                                    <div className="flex-container">
                                        <div className="flex-cell-auto"><Label>From</Label></div>
                                        {fromDate && <DatePicker date={fromDate} setDate={setFromDate} className="flex-cell-fill" />}
                                    </div>
                                </div>
                                <div className="flex-cell-6">
                                    <div className="flex-container">
                                        <div className="flex-cell-auto"><Label>To</Label></div>
                                        {toDate && <DatePicker date={toDate} setDate={setToDate} className="flex-cell-fill" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {searching ? <><Spinner />{searchMessage}</>
                : settlements && <>{(settlements?.length ?? 0) > 0 ?
                    <div className="table-container">
                        <div className="header-container">
                            <div className="header">
                                <Heading level="h3">
                                    Settlements
                                </Heading>
                            </div>
                        </div>
                        <div>
                        <table className='table-content' style={{ width: "100%" }}>
                                <tbody>
                                    <tr className="row header"><th>Settlement Date</th><th>From Date</th><th>Reference</th><th>Total Net Sales</th><th className='btn-col'></th></tr>
                                    {settlements.map(s =>
                                        <tr key={s.SettlementId} className="row">
                                            <td>{moment(s.SettlementDate).format("DD/MM/YYYY")}</td>
                                            <td>{moment(s.FromDate).format("DD/MM/YYYY")}</td>
                                            <td>{s.SettlementId}</td>
                                            <td>{toCurr(s.TotalCollectionsValue)}</td>
                                            <td className='btn-col'>
                                                <Button size="medium" variant='tertiary' onClick={() => doSearchDetail(s)}>Details</Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div><br />
                    </div> 
                :   <div className="table-container">
                        <div className="header-container">
                            <div className="header">
                                <Heading level="h4">
                                    No settlement records found
                                </Heading>
                            </div>
                        </div>                      
                    </div>}<br /></>}
                {searching2 && <Spinner />}
                {(settlementDetails?.length ?? 0) > 0 &&
                    <><div className="table-container">
                        <div className="header-container">
                            <div className="header">
                                <Heading level="h3">
                                    Settlement Details (Reference: {searchedSettlement?.SettlementId})
                                </Heading>
                            </div>
                        </div>
                        <div>
                        <table className='table-content' style={{ width: "100%" }}>
                                <tbody>
                                    <tr className="row header"><th>Product</th><th>Gross Sales</th><th>Less Margin</th><th>Net Sales</th><th className='btn-col'></th></tr>
                                    {settlementDetails.map((s:any) =>
                                        <tr key={s.ProductID} className="row">
                                            <td>{s.ProductName}</td><td>{toCurr(s.GrossSales)}</td>
                                            <td>{toCurr(s.Margin * s.GrossSales)}</td>
                                            <td>{toCurr(s.GrossSales - s.Margin * s.GrossSales)}</td>
                                            <td className='btn-col'>
                                                <Button size="medium" variant='tertiary' onClick={() => doSearchSales(s)}>Details</Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div><br/>
                </>}
                {searching3 && <Spinner />}
                {(productSales?.length ?? 0) > 0 &&
                    <><div className="table-container">
                        <div className="header-container">
                            <div className="header">
                                <Heading level="h3">
                                    Product Sales
                                </Heading>
                            </div>
                        </div>
                        <div>
                        <table className='table-content' style={{ width: "100%" }}>
                                <tbody>
                                    <tr className="row header"><th>Voucher</th><th>Work Centre</th><th>Amount</th><th className='btn-col'></th></tr>
                                    {productSales?.map((s:any, i:number) =>
                                        <tr key={i} className="row">
                                            <td>{s.VoucherControlCode}</td><td>{s.Workcentre}</td>
                                            <td>{toCurr(s.Amount)}</td>
                                            <td className='btn-col'>
                                                <Button size="medium" variant='tertiary' onClick={() => setDetail(s)}>Details</Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div><br />
                </>}
                {detail &&                    
                    <Modal ariaDialogLabel='Sales Details' isModalShowing={detail !== null} onHide={() => { setDetail(null); }}>
                        <>
                            <Modal.Header>
                                Sales Detail: {detail.ReceiptNumber}
                            </Modal.Header>
                            <Modal.Content>
                                <>
                                    <label>Product Name: </label> {detail.ProductName}<br />
                                    <label>Transaction Date: </label> {detail.TransactionDate}<br />
                                    <label>Control Code: </label> {detail.VoucherControlCode}<br />
                                    <label>Receipt Number: </label> {detail.ReceiptNumber}<br />
                                    <label>Workcentre: </label> {detail.WorkcentreName}({detail.Workcentre})<br />
                                    <label>Amount: </label> {toCurr(detail.Amount)}<br /><br />
                                </>
                            </Modal.Content>                            
                        </>
                    </Modal>}
            </div>
        </>
    );
}

export default Settlements;
