import { useState, useEffect, useCallback } from 'react';
import { Heading } from '@auspost/postmaster-react';
import portalClient from "../utils/PortalClient";
import styles from './ReviewStock.module.scss';
import { Spinner } from '../components/Shared';

const ReviewStock = ({ partner }: { partner: any }) => {
    const [tableData, setTableData] = useState<any | null>(null);

    const loadStock = useCallback(async () => {
        try {
            console.log('Stock partnerID', partner?.PartnerID);
            if(!partner?.PartnerID){
                console.log("Partner not loaded yet");
                return;
            }
            let response = await portalClient.post(`/api/portal/dbproxy`,
                {
                    spName: "usp_VoucherStockReport",
                    spParams: { ilngPartnerID: partner.PartnerID }
                });
            console.log("stocks:", response);
            if (response?.data[0]?.ErrorMessage === "No Rows Found")
                setTableData([]);
            else
                setTableData(response.data);
            return;
        }
        catch (e: any) {
            console.error("Search error:", e);
            alert("Error searching");
            return;
        }
    }, [partner?.PartnerID]);

    useEffect(() => {       
        loadStock();
    }, [loadStock]);

    return (
        <>
            <div className={`main container ${styles.reviewStock}`}>
                {/*<Heading level="h1">Stock List</Heading>*/}
                {tableData ? <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                Stock List
                            </Heading>
                        </div>
                    </div>
                    <table className='table-content' style={{ width: "100%" }}>
                        <tbody>
                            <tr className="row header"><th>Product</th><th>Denomination</th><th>Qty Available</th></tr>
                            {tableData.map((i: any, index: number) =>
                                <tr key={`${index} ${i.ProductID} ${i.Denomination}`} className="row"><td>{i.ProductName}</td><td>{i.Denomination}</td><td>{i.Quantity}</td></tr>
                            )}
                            {/*<tr className="row footer"><td><Button size="small" variant="secondary">Show more results</Button></td><td>Showing {tableData.length} of xxx</td><td></td></tr>*/}
                        </tbody>
                    </table>
                </div> : <Spinner />}
            </div>
        </>
    );
}

export default ReviewStock;
