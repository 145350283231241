import React, { useEffect, useState } from 'react';
import { Input } from '@auspost/postmaster-react';
import styles from './ChangeTrackInput.module.scss';
import { Icon } from 'semantic-ui-react'
import autosize from 'autosize';

const ChangeTrackInput = (
        { trackChanges, entity, setEntity, field, fieldType, disabled, required, select, ariaLabelledBy, ...props }:
        { trackChanges: any, entity: any, setEntity: any, field: any, fieldType?: any, disabled?: any, required?: any, select? :Boolean, ariaLabelledBy?: string, items?: any }) => {
    const [originalField, setOriginalField] = useState<string | number | readonly string[] | undefined | null>(undefined);

    const handleChange = (newVal: any) => {
        let newObj = [];
        newObj[field] = newVal;
        setEntity({ ...entity, ...newObj });
    }

    const doAutosize = (e: any) => {
        console.log('doAutosize->e', e);
        autosize(e.target);
    }

    useEffect(() => {
        entity && originalField === undefined && setOriginalField(entity[field]);
    }, [entity, field, setOriginalField, originalField]);


    return (
        <>{entity && <div className={`flex-container ${styles.cti}`} {...props}>
            {trackChanges && <>
                {fieldType === "textarea" ? 
                    <textarea readOnly onFocus={doAutosize} value={originalField ?? undefined} className="flex-cell-fill" /> : 
                    <Input type="text" disabled={true} value={originalField ?? undefined} className={`small flex-cell-fill`}></Input>}
                    <div className='flex-cell-auto change-icon'>{entity[field] !== originalField ? <Icon size='large' name="arrow right" /> : <Icon size='large' />}</div>
            </>}
            {fieldType === "textarea" ? 
                <textarea onFocus={doAutosize} value={entity[field]} onChange={(e) => handleChange(e.target.value)} className="flex-cell-fill" /> : 
                <Input aria-labelledby={ariaLabelledBy} required={required} type="text" disabled={disabled} value={entity[field]} onChange={(e) => handleChange(e.target.value)} className={`small flex-cell-fill`}></Input>}
        </div>}</>
        );
}

export default ChangeTrackInput;