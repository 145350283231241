import { useRef, useEffect } from 'react';
import { Input } from '@auspost/postmaster-react';
import styles from './DatePicker.module.scss';
import AirDatepicker from 'air-datepicker';
import "air-datepicker/air-datepicker.css";
import localeEn from 'air-datepicker/locale/en';

const DatePicker = ({ className, date, setDate, ...props }: {className?: any, date?: any, setDate?: any}) => {

    let $input = useRef<HTMLInputElement>(null);
    let dp = useRef<AirDatepicker<HTMLElement>>();

    // Start init
    useEffect(() => {
        // Save instance for the further update
        //console.log("initial date", date); //Don't add as useEffect dependancy!!
        if($input.current) {            
            dp.current = new AirDatepicker($input.current, { ...props, onSelect: (e) => { 
                console.log("Select date", e); setDate(e.date); }, selectedDates: [date], 
                altField: "", altFieldDateFormat: "T",
                dateFormat: "dd/MM/yyyy", locale: localeEn, position: "bottom right", autoClose: true 
            });
            return () => {
                //console.log("dp.current.destroy()", dp.current);
                // This breaks jest so skip it if running jest...
                if(process.env.JEST_WORKER_ID !== undefined)
                    console.log("Skip destroy!");
                else
                    dp.current?.destroy();
            };
        }
    }, [props, setDate, date]);

    useEffect(() => {
        // Update if props are changed
        dp.current?.update({ ...props });
    }, [props]);

    return (
        <div {...props} className={`${styles.datePicker} ${className}`}>
            <div className="dp-fields">
                <Input ref={$input} type="text" className="small" />
                <svg onClick={() => $input.current?.focus()} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.75 5.995H1.5V16.1317C1.5 16.3388 1.668 16.4994 1.875 16.4994H15.375C15.582 16.4994 15.75 16.3388 15.75 16.1317V5.995ZM17.25 2.24343V16.1317C17.25 17.1679 16.4107 18 15.375 18H1.875C0.83925 18 0 17.1679 0 16.1317V2.24343H3V0.750313C3 0.33614 3.336 0 3.75 0C4.164 0 4.5 0.33614 4.5 0.750313V2.24343H12.75V0.750313C12.75 0.33614 13.086 0 13.5 0C13.914 0 14.25 0.33614 14.25 0.750313V2.24343H17.25ZM11.25 14.2484H13.5V11.9975H11.25V14.2484ZM7.5 14.2484H9.75V11.9975H7.5V14.2484ZM3.75 14.2484H6V11.9975H3.75V14.2484ZM11.25 10.4969H13.5V8.24594H11.25V10.4969ZM7.5 10.4969H9.75V8.24594H7.5V10.4969ZM3.75 10.4969H6V8.24594H3.75V10.4969Z" fill="#212129" />
                </svg>
            </div>
        </div>
        );
}

export default DatePicker;