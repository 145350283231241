import { useState, useCallback } from 'react';
import { Heading, Button, Label, Input, Notification } from '@auspost/postmaster-react';
import styles from './ProductUpdate.module.scss';
import ProductSelect from '../components/molecules/ProductSelect';
import ProductDetail, {emptyProduct, isValidProduct} from '../components/organisms/ProductDetail';
import portalClient from '../utils/PortalClient';
import { useNavigate } from 'react-router-dom';

const ProductUpdate = ({partner}: {partner: any}) => {
    const [product, setProduct] = useState(emptyProduct);
    const [detailVersion, setDetailVersion] = useState(1);
    const [productDetail, setProductDetail] = useState(emptyProduct);
    const [editingProduct, setEditingProduct] = useState<any | null>(null);
    const [invalidFields, setInvalidFields] = useState<string[]>([]);
    let navigate = useNavigate();

    const setSelectedProductDetail = useCallback((prod: any) => {
        setProductDetail({...emptyProduct, ...prod});
        setEditingProduct(null)
    }, []);

    const doEdit = () => {
        setEditingProduct(productDetail);
    }

    const doCancel = () => {
        setEditingProduct(null);
        setInvalidFields([]);
    }

    const doSave = async () => {
        if (!isValidProduct(editingProduct, setInvalidFields))
            return;
        console.log('saving product...', editingProduct);        
        try {
            let response = await portalClient.put(`/api/portal/products/${editingProduct.ProductID}`, editingProduct);
            console.log('product saved', response.data);
            // notify product select that this product has changed...
            setDetailVersion(detailVersion+1);
            setEditingProduct(null);
            setInvalidFields([]);
        }
        catch(err:any) {
            let errorMsg = err.response?.data?.ErrorMessage;
            alert(`Error Saving Product${errorMsg ? `: ${errorMsg}` : ". Please ensure all mandatory fields have been supplied"}`);
            console.log(`Error Saving Product`, err);
            console.log(editingProduct);
        }
    }

    const doSetStatus = async (newStatus: any) => {
        console.log('Setting product status...', product, newStatus);

        let statusId = newStatus === "Available" ? 1 : (newStatus === "Withdrawn" ? 3 : 2);
        let response = await portalClient.post(`/api/portal/dbproxy`, 
            { 
                spName: "usp_ProductSetStatus", 
                spParams: {intProductID: product.ProductID, intStatusID: statusId, strPartner: partner.PartnerName, strUsername: "USER"}
            });
        console.log("status set", response);
        setDetailVersion(detailVersion+1);
    }

    return (
        <>
            <div className={`main container ${styles.productUpdate}`}>
                {/*<Heading level="h1">New Product Definition</Heading>*/}
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">                                
                                Select Product
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doEdit}>Modify Selected Product</Button>
                            </div>
                        </div>
                    </div>
                    <div className="table-content">
                        <div className='flex-container fields row'>
                            <div className='flex-row'>
                                <div className="flex-cell-8"><ProductSelect product={product} setProduct={setProduct} 
                                    setProductDetail={setSelectedProductDetail} partner={partner} detailVersion={detailVersion} /></div>
                                <div className="flex-cell-4">{product?.ProductID && <><Label>Current Status</Label><Input type="text" className='small' disabled value={productDetail?.ProductStatus || "Loading..."}></Input></>}</div>
                            </div>                            
                        </div>
                        {product?.ProductID &&
                        <div className='flex-container fields row'>
                            <Notification isDismissible={false} className='flex-cell-5' message="NOTE: Any changes or additions made here will now take immediate effect. Please ensure they are correct." status="info" type="alert" />
                            <div className='actions flex-cell-fill'>
                                {productDetail?.ProductStatus !== "Loading..." ? <>
                                    <Button size="small" variant="secondary" onClick={() => navigate(`/product-definition/denominations/${productDetail?.ProductID}`)}>Modify Denomination</Button>                            
                                    {(productDetail?.ProductStatus !== "Withdrawn") &&
                                        <Button size="small" variant="secondary" onClick={() => doSetStatus("Withdrawn")}>Withdraw from Sale</Button>}
                                    {(productDetail?.ProductStatus !== "Suspended") && 
                                        <Button size="small" variant="secondary" onClick={() => doSetStatus("Suspended")}>Suspend from Sale</Button>}
                                    {(productDetail?.ProductStatus !== "Available") && 
                                        <Button size="small" variant="secondary" onClick={() => doSetStatus("Available")}>Re-enable for Sale</Button>}
                                </> : <>&nbsp;</>}
                            </div>
                        </div>}
                    </div>
                </div><br/>
                {editingProduct?.ProductID && <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                Update Product
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doSave}>Save</Button>
                                <Button size="small" variant="secondary" onClick={doCancel}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                    {invalidFields?.length > 0 &&
                    <div className="table-content">
                        <div className='flex-container row fields'>
                            <Notification isDismissible={false} className='flex-cell-fill' style={{ marginBottom: "1em" }}
                                message={<>Missing required fields: {invalidFields.join(", ")}</>} status="error" type="alert" />
                        </div>
                    </div>}                    
                    <div className="table-content">
                        <div className="flex-container row fields">
                            <ProductDetail partner={partner} showChanges={true} product={editingProduct} setProduct={setEditingProduct} />
                        </div>
                        <div className="flex-container row fields actions">
                            <Button size="small" variant="primary" onClick={doSave}>Save</Button>
                            <Button size="small" variant="secondary" onClick={doCancel}>Cancel</Button>
                        </div>
                        {invalidFields?.length > 0 &&
                            <div className='flex-container row fields'>
                                <Notification isDismissible={false} className='flex-cell-fill' style={{ marginTop: "-1em" }}
                                    message={<>Missing required fields: {invalidFields.join(", ")}</>} status="error" type="alert" />
                            </div>}   
                    </div>
                </div>}
            </div>
        </>
    );
}

export default ProductUpdate;
