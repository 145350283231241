import { useEffect, useState } from 'react';
import { Button, Input, Label, Heading, Notification } from '@auspost/postmaster-react';
import styles from './ProductStatus.module.scss';
import portalClient from '../utils/PortalClient';
import { Spinner } from '../components/Shared';
import { emptyProduct } from '../components/organisms/ProductDetail';
import ProductSelect from '../components/molecules/ProductSelect';

const ProductStatus = ({partner}: {partner: any}) => {
    const [product, setProduct] = useState(emptyProduct);
    const [productDetail, setProductDetail] = useState(emptyProduct);
    const [productStatusReport, setProductStatusReport] = useState<any | null>(null);
    const [searching, setSearching] = useState(false);

    useEffect(() => {}, [])

    const doSearch = async () => {
        console.log('Searching...', JSON.stringify(product), );
        setProductStatusReport(null);
        setSearching(true);
        
        portalClient.post(`/api/portal/dbproxy`, 
            { 
                spName: "usp_VoucherProductStatusReport", 
                spParams: {ilngProductID: product.ProductID}})
        .then((response:any) => {
            console.log("product status report:", response);
            setProductStatusReport(response.data);
            setSearching(false);
        })
        .catch((e:any) => {
            alert("Error getting product status report");
            console.error("Error getting product status report", e);
            setSearching(false);
        });
    }

    return (
        <>
            <div className={`main container ${styles.productStatus}`}>
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                Product Status Report
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doSearch}>Search</Button>
                            </div>
                        </div>
                    </div>
                    <div className="table-content">
                        <div className="flex-container row fields">
                            <div className="flex-row">
                                <ProductSelect className="flex-cell-4" product={product} setProduct={setProduct} 
                                    setProductDetail={setProductDetail} partner={partner} />
                                <div className="flex-cell-2">
                                    <Label>Product status</Label>
                                    <Input type="text" className='small' disabled value={productDetail?.ProductStatus || "Loading..."}></Input>                                
                                </div>
                                <div className='flex-cell-fill'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {searching ? <Spinner /> :
                productStatusReport?.length > 0 &&                
                    <><div className="table-container">
                        <div className="header-container">
                            <div className="header">
                                <Heading level="h3">
                                    Product Status Report
                                </Heading>
                            </div>
                        </div>
                        <div>
                        <table className='table-content' style={{ width: "100%" }}>
                                <tbody>
                                {productStatusReport?.length === 1 && productStatusReport[0].StatusCode === 1 ? 
                                    <tr>
                                        <td></td><td>
                                        <Notification isDismissible={false} className='flex-cell-fill' status="info" type="alert"
                                            message={productStatusReport[0].ErrorMessage} /><br />
                                        </td><td></td>
                                    </tr> : 
                                <> 
                                    <tr className="row header">
                                        <th>Denomination</th>
                                        <th>Status</th>
                                        <th>Quantity</th>
                                        <th>Total Value</th>
                                        </tr>
                                    {productStatusReport.map((s: any, i: number) =>
                                        <tr key={i} className="row">
                                            <td>{s.Denomination}</td>
                                            <td>{s.VoucherStatus}</td>
                                            <td>{s.Quantity}</td>
                                            <td>{(s.TotalValue/100.0)?.toLocaleString("en-AU", {style: "currency", currency: "AUD", minimumFractionDigits: 2})}</td>
                                        </tr>
                                    )}
                                </>}
                                </tbody>
                            </table>
                        </div><br />
                    </div>
                </>}
            </div>
        </>
    );
}

export default ProductStatus;
