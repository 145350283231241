import { useEffect, useCallback, useState } from 'react';
import { Label, Select, InputField } from '@auspost/postmaster-react';
import styles from './EntitySelect.module.scss';
import portalClient from '../../utils/PortalClient';

const DenominationSelect = ({ product, denomination, setDenomination, className, version, allowAll, allowProducts, ...props }: 
                        { product: any, denomination?: any, setDenomination?: any, className?: any, version:number, allowAll?:boolean, allowProducts?: any}) => {
   
    const [denominations, setDenominations] = useState<any[] | null>(null);
    const [denominationSummary, setDenominationSummary] = useState<any | null>(null);

    const loadDenominations = useCallback(async () => {
        setDenominationSummary(null);

        let denominations:any[] = [];

        //allow all products and all denominations
        if((allowAll??false) && product?.ProductID && product?.ProductID === -1 && allowProducts && allowProducts?.length > 0){
            console.log("Allow all denominations for all products", allowProducts);
            
            for(let i=0; i<allowProducts.length; i++){
                let p = allowProducts[i];
                let response = await portalClient.get(`/api/portal/denominations?intProductID=${p.ProductID}`);
                console.log(`all Denominations for product ${p.ProductID}`, response.data);
                let denominationsForProduct = response.data;
                denominationsForProduct?.forEach((d:any) => d.strStatus = d.strStatus ?? "");
                denominations.push(...denominationsForProduct);
            }
    
            //allow all denominations
            denominations.unshift({DenominationID: -1, Denomination: 'All Denominations'});

            setDenominations(denominations);
        }else if(product?.ProductID && product?.ProductID >= 0){
            portalClient.get(`/api/portal/denominations?intProductID=${product.ProductID}`).then((response:any) => {
                console.log(`Denominations for product ${product.ProductID}`, response.data);
                denominations = response.data;
    
                denominations?.forEach((d:any) => d.strStatus = d.strStatus ?? "");

                setDenominations(denominations);
            }).catch((err:any) => {console.log("Error loading denominations", err);})
        }
    }, [product.ProductID, setDenominationSummary, allowAll, allowProducts]);

    const loadDetails = useCallback(async() => {                
        if(!denominationSummary?.DenominationID || (denomination?.strDenomination === denominationSummary?.Denomination && denomination?.strStatus !== "Loading..."))
            return;        
        // now load details
        console.log("loadDetails()...", denomination?.strDenomination , denominationSummary?.Denomination);
        try {
            let response = await portalClient.post(`/api/portal/dbproxy`, 
                { spName: "usp_DenominationSelectByID", spParams: {intDenominationID: denominationSummary?.DenominationID}});
            console.log("Got details...", response.data);
            setDenomination({...denominationSummary, ...response.data[0]});
        }
        catch(err:any) {
            console.error("Error loading denomination details", err);
            alert("Error loading denomination details");
        }
    }, [denominationSummary, denomination?.strDenomination, setDenomination, denomination?.strStatus]);

    useEffect(() => {      
        if(product?.ProductID && product?.ProductID >= 0)
            loadDenominations();
        else if(product?.ProductID && product?.ProductID === -1){
            loadDenominations();
        }//allow all denominations
    }, [product?.ProductID, loadDenominations, version]);

    useEffect(() => {
        loadDetails();
    }, [denominationSummary?.DenominationID, loadDetails, setDenomination, denomination?.strStatus]);


    useEffect(() => {   
        console.log('Denominations changed', denominations, denominations?.length && denominations?.length > 0);           
        if(denominations?.length && denominations?.length > 0) {
            let selectedI = denominations.findIndex((p: any) => p.DenominationID === denominationSummary?.DenominationID);
            console.log("selecting Denomination: ", selectedI);
            let selEntity = denominations[selectedI < 0 ? 0 : selectedI];
            setDenominationSummary(selEntity);
        }
    }, [denominations, denominationSummary?.DenominationID, setDenominationSummary]);

    return (
        <div {...props} className={`${className} ${styles.entitySelect}`}>
            <Label>Select Denomination</Label>
            {denominations ? <Select value={denominationSummary?.DenominationID}
                placeholder='Select Denomination'
                options={denominations.map((p: any) => { return { label: p.strDenomination ?? p.Denomination, value: p.DenominationID, key: p.DenominationID } })}
                onChange={(v) => { 
                    let e = denominations.find((p: any) => p.DenominationID.toString() === v.target.value); 
                    console.log('select change', e, v.target.value); 
                    setDenominationSummary(e);
                 }}
            /> : 
            <InputField disabled loading />}
        </div>
        );
}

export default DenominationSelect;