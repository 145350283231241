import React, { Component } from 'react';
import NavMenu from './NavMenu';

export class Layout extends Component<{partner: any, selectPartner: any, logout: any, user: any, children: any}, {}> {
    static displayName = Layout.name;

    render() {
        return (
            <div className="main">
                <NavMenu {...this.props} />
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
