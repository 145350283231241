import { useEffect, useCallback, useState } from 'react';
import { Label, Select, InputField } from '@auspost/postmaster-react';
import styles from './EntitySelect.module.scss';
import portalClient from '../../utils/PortalClient';
import { emptyProduct } from '../organisms/ProductDetail';
import { useParams } from 'react-router-dom';

const ProductSelect = ({ setProduct, partner, setProductDetail, detailVersion, product, className, allowAll, setAllowProducts, ...props }: 
                        { setProduct: any, partner: any, setProductDetail?: any, detailVersion?: any, product?: any, className?: any, allowAll?: boolean, setAllowProducts?:any}) => {
   
    const [products, setProducts] = useState<any[] | null>(null);
    const params = useParams();
    const [productList, setProductList] = useState<any[] | undefined>();

    const loadProducts = useCallback(async () => {
        console.log('Loading products...');
        if(!partner?.PartnerID){
            console.log("Partner not loaded yet");
            return;
        }
        try {
            let response =  await portalClient.get(`/api/portal/products?ilngPartnerID=${partner.PartnerID}`);
            console.log('products', response.data);

            //allow all products
            (allowAll??false) && response.data.unshift({ProductID: -1, ProductName: 'All Products'});
            setAllowProducts && setAllowProducts(Array.from(response?.data)?.filter((p:any) => p.ProductID !== -1));

            //response.data.unshift({ProductID: -1, ProductName: 'All Products'});
            setProducts(response.data);
            setProductList(response.data?.map((p: any) => { return { label: p.ProductName, value: p.ProductID, key: p.ProductID } }));
        }
        catch(err: any) {
            console.error("Error in loadProducts()", err);
            alert("Error loading products");
        }
    }, [allowAll, partner?.PartnerID, setProducts, setAllowProducts]);

    useEffect(() => {      
        if(partner?.PartnerID)         
            loadProducts();
    }, [partner?.PartnerID, loadProducts]);

    useEffect(() => {    
        const loadDetails = async(productId:any) => {
            // now load details if required
            //console.log('product detail', setProductDetail);
            if(!setProductDetail || !productId || productId < 0)
                return;        
            console.log('Loading product detail...', productId, detailVersion);
            setProductDetail(emptyProduct);
            try {
            let response = await portalClient.post(`/api/portal/dbproxy`, 
                { spName: "usp_ProductSelectByID", spParams: {intProductID: productId}});
                let detail = response.data[0];
                console.log('product detail', detail);
                let newProductList = products?.map((p: any) => { return { label: p.ProductName, value: p.ProductID, key: p.ProductID } })
                setProductDetail(detail);
                // also update product list with potentially updated name
                newProductList = newProductList?.map(el => (el.value === response.data[0].ProductID ? {...el, label: detail.ProductName} : el));
                setProductList(newProductList);
            }
            catch(err:any){
                console.error("Error in loadDetails()", err);
                alert("Error loading details");
            }
        }  
        loadDetails(product?.ProductID);
    }, [product?.ProductID, setProductDetail, detailVersion, products]);


    useEffect(() => {   
        console.log('products changed', products);           
        if(products?.length && products?.length > 0) {
            let selectedI = products.findIndex((p: any) => p.ProductID === product?.ProductID);
            console.log("selecting product: ", selectedI);
            // if no product selected, check url for default
            if(selectedI < 0 && params?.pid)
            {
                console.log("Selected default from params: ", params, products);
                selectedI = products.findIndex((p: any) => p.ProductID.toString() === params.pid);
            }
            let selProd = products[selectedI < 0 ? 0 : selectedI];
            setProduct(selProd);
        }
    }, [products, product?.ProductID, setProduct, params]);

    return (
        <div {...props} className={`${className} ${styles.entitySelect}`}>
            <Label id="product-label">Select product</Label>
            {productList ? <Select aria-labelledby='product-label' value={product?.ProductID}
                placeholder='Select SKU'
                options={productList}
                onChange={(v) => { 
                    let prod = products?.find((p: any) => p.ProductID?.toString() === v.target.value); 
                    console.log('select change', prod); 
                    setProduct(prod);
                 }}
            /> : 
            <InputField disabled loading />}
        </div>
        );
}

export default ProductSelect;