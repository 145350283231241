import { useEffect, useState } from 'react';
import { Button, Select, Label, Heading, Infotip } from '@auspost/postmaster-react';
import styles from './ProductAnalysisSummary.module.scss';
import DatePicker from '../components/DatePicker';
import portalClient from '../utils/PortalClient';
import { Spinner } from '../components/Shared';
import moment from 'moment';
import { emptyProduct } from '../components/organisms/ProductDetail';
import ProductSelect from '../components/molecules/ProductSelect';
import {toCurr, validateDates} from '../utils/utils';

const ProductAnalysisSummary = ({partner}: {partner: any}) => {
    const [product, setProduct] = useState(emptyProduct);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [productAnalysisSummaries, setProductAnalysisSummaries] = useState<any[]| null>(null);
    const [searching, setSearching] = useState(false);
    const [statuses, setStatuses] = useState<any[]>([]);
    const [status, setStatus] = useState<number>(-1);

    const datePeriods = [{label: "Daily", value: "day"},{label: "Weekly", value: "week"},{label: "Monthly", value: "month"}];
    const [datePeriod, setDatePeriod] = useState<string>("week");
    const locationScales = [{label: "Work Centre (Post Office)", value: "workcentre"},{label: "National", value: "national"},{label: "State", value: "state"}];
    const [location, setLocation] = useState<string>("workcentre");

    useEffect(() => {
        let endDate = new Date();
        let startDate = new Date(endDate.setDate(endDate.getDate() - 7));
        endDate = new Date();
        console.log("Start/End date", startDate, endDate);
        setFromDate(startDate);
        setToDate(endDate);
    }, []);

    useEffect(() => {
        setStatuses([{label: "All", value: -1}, 
            ...(partner?.voucherStatuses ?? []).map(
                (s:any) => { return {value: s.id, label: s.description}})]);
    }, [partner?.voucherStatuses]);

    const doSearch = async () => {
        if(!fromDate || !toDate || !validateDates(fromDate, toDate))
            return;
        // console.log('Searching...', fromDate?.toJSON(), toDate?.toJSON());
        const strFromDate = moment.utc(moment(fromDate).format('YYYY-MM-DD')).format('YYYY-MM-DDTHH:mm:ss.SSS');
        const strToDate = moment.utc(moment(toDate).format('YYYY-MM-DD')).format('YYYY-MM-DDTHH:mm:ss.SSS');
        console.log("Searching1...", strFromDate, strToDate);
        setProductAnalysisSummaries(null);
        setSearching(true);
        try {
            // let response = await portalClient.get(`/api/portal/productanalysisreport?partnerId=${partner?.PartnerID}&productId=${product.ProductID}`+
            //         `&date_gte=${fromDate?.toJSON()}&date_lte=${toDate?.toJSON()}&primaryLevel=${datePeriod}&secondaryLevel=${location}&voucherStatus=${status}`);
            let response = await portalClient.get(`/api/portal/productanalysisreport?partnerId=${partner?.PartnerID}&productId=${product.ProductID}`+
                    `&date_gte=${strFromDate}&date_lte=${strToDate}&primaryLevel=${datePeriod}&secondaryLevel=${location}&voucherStatus=${status}`);
            console.log('Got productanlysisreport:', response.data);
            setProductAnalysisSummaries(response.data);
            setSearching(false);
        } catch(e) {
            alert("Error getting productAnalysisSummaries");
            console.error("Error getting productAnalysisSummaries", e);
            setSearching(false);
        }
    }

    return (
        <>
            <div className={`main container ${styles.productAnalysisSummary}`}>
                {/*<Heading level="h1">Product Analysis Detailed Report</Heading>*/}
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                Product Analysis Summary
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doSearch}>Search</Button>
                            </div>
                        </div>
                    </div>
                    <div className="table-content">
                        <div className="flex-container row fields">
                            <div className="flex-row">
                                <h3 className='flex-cell-12'>
                                    General Filters&nbsp;
                                    <Infotip size="large">These filters can be used to alter the amount of information returned by the reports.</Infotip>
                                    </h3>
                            </div>
                            <div className="flex-row">
                                <div className="flex-cell-3">
                                    <label>Start Date</label>
                                    <div className="flex-container">                                        
                                        {fromDate && <DatePicker date={fromDate} setDate={setFromDate} className="flex-cell-fill" />}
                                    </div>
                                </div>
                                <div className="flex-cell-3">
                                    <label>End Date</label>
                                    <div className="flex-container">
                                        {toDate && <DatePicker date={toDate} setDate={setToDate} className="flex-cell-fill" />}
                                    </div>
                                </div>
                                {/* allow all products: added allowAll for ProductAnalysisSummary */}
                                <ProductSelect className="flex-cell-4" product={product} setProduct={setProduct} partner={partner} allowAll={true}/>
                                <div className="flex-cell-2">
                                    <Label>Status</Label>
                                    <Select options={statuses} value={status}
                                        onChange={(v) => { 
                                            //setRecords(null);
                                            let val = statuses.find((d: any) => d.value.toString() === v.target.value); 
                                            //console.log("selected val, v.target:", val, v.target.value, statuses);
                                            setStatus(val?.value ?? -1);
                                        }}
                                    />
                                </div>
                            </div>
                            <hr className="flex-row" />
                            <div className="flex-row">
                                <h3 className='flex-cell-12'>
                                    Grouping options&nbsp;
                                    <Infotip size="large">These grouping options can be used to restrict the type of information returned by the reports.</Infotip>
                                </h3>
                            </div>
                            <div className="flex-row">
                                <div className="flex-cell-4">
                                    <Label>Date Period</Label>
                                    <Select options={datePeriods} value={datePeriod}
                                        onChange={(v) => { 
                                            setProductAnalysisSummaries(null);
                                            let val = datePeriods.find((d: any) => d.value === v.target.value); 
                                            setDatePeriod(val?.value ?? "");
                                        }}
                                    />
                                </div>
                                <div className="flex-cell-4">
                                    <Label>Location Scale</Label>
                                    <Select options={locationScales} value={location}
                                        onChange={(v) => { 
                                            setProductAnalysisSummaries(null);
                                            let val = locationScales.find((l: any) => l.value === v.target.value); 
                                            setLocation(val?.value ?? "");
                                        }}
                                    />
                                </div>
                                <div className='flex-cell-fill'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {searching ? <Spinner />
                : (productAnalysisSummaries?.length ?? 0) > 0 ?
                    <><div className="table-container">
                        <div>&nbsp;</div>
                        {/*<div className="header-container">
                            <div className="header">
                                <Heading level="h3">
                                    Product Analysis Summaries
                                </Heading>
                            </div>
                        </div>*/}
                        <div>
                        <table className='table-content' style={{ width: "100%" }}>
                                <tbody>
                                    <tr className="row header">
                                        <th>
                                            {   datePeriod === "day" ? "Date"
                                                : datePeriod === "week" ? "Week Ending"
                                                : "Month"}
                                        </th>
                                        <th>
                                            {   location === "workcentre" ? "Workcentre"
                                                : location === "state" ? "State"
                                                : "Location"}
                                        </th><th>Product</th><th>Denomination</th><th>Total Value</th><th>Total Transactions</th></tr>
                                    {productAnalysisSummaries?.map((s: any) =>
                                        <tr key={s.id} className="row">
                                            <td>{datePeriod === "day" ? moment(s.SaleDate).format("DD/MM/YYYY")
                                                : datePeriod === "week" ? moment(s.WeekEnding).format("DD/MM/YYYY")
                                                : s.Month}</td>
                                            <td>{location === "workcentre" ? s.Workcentre
                                                : location === "state" ? s.State
                                                : s.Region}</td>
                                                <td>{s.ProductName}</td><td style={{paddingLeft: "1vw"}}>{s.Denomination}</td>
                                            <td>{toCurr(s.TotalValue)}</td>
                                            <td>{s.TransactionCount}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div><br />
                    </div><br />                    
                </>
                : (productAnalysisSummaries?.length === 0 && <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h4">
                                No records found
                            </Heading>
                        </div>
                    </div>                      
                </div>)}
            </div>
        </>
    );
}

export default ProductAnalysisSummary;
