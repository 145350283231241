import {useState, useEffect, useCallback} from 'react';
import { Button, Select, Label, Notification, Heading } from '@auspost/postmaster-react';

function Home({selectedPartner, setSelectedPartner, portalClient, user}: {selectedPartner:any, setSelectedPartner:any, portalClient: any, user: any}) {
    const [partner, setPartner] = useState<any|null>(null);
    const [partners, setPartners] = useState<any[]|null>(null);

    const selectAndGetPartnerDetails = useCallback(async (p: any) => {
        console.log('selectAndGetPartnerDetails...', p); 
        setPartner(p);
        if(!p?.PartnerID) {
            console.log("Missing p.PartnerID.", p);            
            return;
        }
        try{
            let response = await portalClient.get(`/api/portal/config/${p.PartnerID}`);
            console.log('Got Partner config', response); 
            let newPartner = {...p, ...response.data};
            setSelectedPartner(newPartner);
            window.localStorage.setItem("VoucherPlusCurrentPartner", JSON.stringify(newPartner));
        } 
        catch(err:any) {
            alert("Sorry error loading config");
            console.log("Error loading config", err);
        }        
    }, [portalClient, setSelectedPartner]);
    const loadPartners = useCallback(() => {
        console.log("get /api/portal/partners...");
        portalClient.get(`/api/portal/partners`).then((response:any) => {
            setPartners(response.data?.partners?.filter((p:any) => p.PartnerID > 0) ?? response.data?.filter((p:any) => p.PartnerID > 0));
        }).catch((err:any) => {
            alert("Sorry error loading partners");
            console.log("Error loading partners", err);
        });
    }, [portalClient]);

    useEffect(() => {
        console.log("Trying to loadPartners()...");
        if(user) {
            console.log("loadPartners()...");
            loadPartners();
        }
    }, [user, loadPartners]);

    useEffect(() => {      
        if(!partners?.length || partners[0]?.PartnerID === selectedPartner?.PartnerID)
            return;

        // if there's only one auto select it
        if(partners?.length === 1 && partners[0]?.PartnerID !== selectedPartner?.PartnerID){
            console.log("Selecting first", partners[0]?.PartnerID, selectedPartner?.PartnerID);
            selectAndGetPartnerDetails(partners[0]);
        }
        
        // if there's multiple then set the selected one
        if(!partner?.PartnerID && partners?.length > 0 && partners[0]?.PartnerID !== partner?.PartnerID) {
            console.log("setting partner:", partners?.length > 0 && partners[0]);
            setPartner(partners[0]);
        }
      }, [partners, selectAndGetPartnerDetails, selectedPartner?.PartnerID, partner?.PartnerID]);

    return (
        <div className={`main container`}>
            {!(partner || selectedPartner) ? <>Loading...</> :
                <>
                    {(partners && selectedPartner === null) ? <>
                        <Heading level="h1">Partner Selection</Heading>
                        <div className="table-container">
                            <div className="header-container">
                                <div className="header">
                                    <Heading level="h3">
                                        Partner Selection
                                    </Heading>
                                    <div className="actions">
                                        <Button size="small" variant="primary" onClick={() => selectAndGetPartnerDetails(partner)}>Submit</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-content fields">
                                <div className="flex-container row fields">
                                    <div className='flex-cell-fill'>
                                        <Label>Partner List</Label>
                                        <Select style={{ maxWidth: "30em" }}
                                            placeholder='Select Partner'
                                            options={partners.map((v: any) => { return { label: v.PartnerName, value: v.PartnerID, key: v.PartnerID } })}
                                            onChange={v => { let p = partners.find((p: any) => p.PartnerID?.toString() === v.target.value); setPartner(p); }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> :
                        <Notification
                            isDismissible={false}
                            message={<>Welcome to the Partner Portal. Please use the menu to select what you would like to do. (Partner: {selectedPartner?.PartnerName})</>}
                            status="success"
                            type="notice"
                        />}
                </>
            }
        </div>
    );
}

export default Home;